<template>
  <div class="card-body draft-list project-list alllistView">
    <p class="text-sm text-secondary" v-if="!projectList || projectList.data.length === 0">Es wurden keine Projekte
      gefunden.</p>
    <Sort @sort="resortOrder($event)"/>
    <div class="draft-item" v-for="project in projectList.data"
         :key="project.id">
      <div class="draftLineWrap projectLine"
           :class="(project.prepared && project.prepared.typeString) ? project.prepared.typeString : ''"
           v-if="project.state !== 'deleted' || $store.state.filters.projects.state.value === 'deleted'">
        <div class="lineitem first l-checkbox">
          <div class="checkbox">
            <input type="checkbox"
                   :id="'id_'+project.id"
                   :value="project.id"
                   v-if="projectList && projectList.selected"
                   v-model="projectList.selected[project.id]"/>
            <label :for="'id_'+project.id"></label>
          </div>
        </div>
        <div class="lineitem l-title" @click="$store.commit('closeProject');setDraft(project)">
          {{ project.title }}
        </div>
        <div class="lineitem l-state">
          <template v-if="project.state === 'deleted'">
            <span class="badge w-100 bg-danger badge-xs" v-if="project.state"
                  :class="'state_'+project.state">{{ $t('project.state.' + project.state) }}</span>
          </template>
          <template v-else-if="project.state === 'prepare'">
            <span class="badge w-100 bg-warning badge-xs" v-if="project.state"
                  :class="'state_'+project.state">{{ $t('project.state.' + project.state) }}</span>
          </template>
          <template v-else-if="project.state === 'order'">
            <span class="badge w-100 bg-success badge-xs" v-if="project.state"
                  :class="'state_'+project.state">{{ $t('project.state.' + project.state) }}</span>
          </template>
          <template v-else>
            <span class="badge w-100 bg-primary badge-xs" v-if="project.state"
                  :class="'state_'+project.state">{{ $t('project.state.' + project.state) }}</span>
            <span v-else>-</span>
          </template>
        </div>

        <div class="lineitem l-customer">
          <span v-if="project.c_company || (project.c_firstname && project.c_lastname)">
            <template v-if="project.c_company">{{ project.c_company }}<br></template>
            {{ project.c_firstname }} {{ project.c_lastname }}
          </span>
        </div>
        <div class="lineitem l-issue_count open">
          <span > {{ project._openIssuesCount }} / {{ project._issuesCount }}</span>
        </div>

        <Holzkraft :project="project" mode="listHeadline"
                   v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft"/>
        <div class="lineitem l-date" :tooltip="'erstellt'" :position="'left'"><i
          class="fa-duotone fa-calendar-circle-plus"></i> {{ $filters.formatDate(project.crm_created_date) }}
        </div>
        <div class="lineitem l-date" :tooltip="'bearbeitet'" :position="'left'"><i
          class="fa-duotone fa-calendar-circle-user"></i> {{ $filters.formatDate(project.crm_modified_date) }}
        </div>
        <div class="lineitem l-avatar ml-auto">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, project.employee_id, 'id')"
               class="sketchAvatar" v-if="project.employee_id">
        </div>
        <div class="lineitem last ms-2">
          <template v-if="projectActive && projectActive.id === project.id">
            <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeProject');"></i>
          </template>
          <template v-else><i class="fa-duotone fa-chevrons-up" @click="setDraft(project)"></i></template>
        </div>
      </div>
    </div>
    <pagination list="projects" listName="projectList" filterName="projectsFilter"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Edit from "@/routing/core/Projects/Edit.vue";
import Details from "@/routing/core/Projects/Details.vue";
import Headline from "@/routing/core/Projects/Layouts/Headline.vue";
import Sort from "@/routing/core/Projects/Layouts/Sort.vue";
import Pagination from "@/filter/components/pagination.vue";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";

export default {
  name: "List",
  emits: ['refresh-project-list', 'new-project', 'project-active'],
  components: {Holzkraft, Pagination, Sort, Headline, Details, Edit},
  props: {},
  data() {
    return {
      newProjectItem: {},
      accessData: [],
      projects: []
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "modalsList", "projectList", "projectActive", "getPlugins"])
  },
  methods: {
    setDraft(project) {
      this.$store.commit('closeProject');
      // next tick
      this.$nextTick(() => {
        this.$store.dispatch("setActiveProject", project);
        this.$router.push({name: 'ProjectDetails', params: {id: project.id}})
        this.scrollToTop();
      });
    },
    resortOrder(payload) {
      if (payload.direction) {
        this.$store.state.filters.projects.ordering.value = payload.type;
        this.$store.state.filters.projects.direction.value = payload.direction;
      } else {
        this.$store.state.filters.projects.ordering.value = payload.type
      }
      this.$store.dispatch("projectsFilter");
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.$store.dispatch("projectsFilter");
  },

}
</script>

