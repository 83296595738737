<template>
  <div class="card w-100">
    <div class="card-header">
      <h6 style="display: inline; margin-right: 24px;">Freigaben</h6>
      <div class="form-group form-group-sm me-2" style="display: inline-block;">
        <input type="text" class="form-control small" v-model="searchAttest" style="width: 150px;" placeholder="Suche"/>
      </div> <div class="form-group form-group-sm me-2" style="display: inline-block;">
        <input type="date" class="form-control small" v-model="searchDate" style="width: 150px;" placeholder="Suche"/>
      </div>
      <div class="form-group form-group-sm me-2" style="display: inline-block;">
        <select class="form-select small" v-model="licenseFilter" style="width: 180px;">
          <option value="all">- Lizenz wählen -</option>
          <option v-for="title in filters.titles" :value="title">{{title}}</option>
        </select>
      </div>
      <div class="form-group form-group-sm me-2" style="display: inline-block;">
        <select class="form-select small" v-model="stateFilter" style="width: 180px;">
          <option value="all">- Status wählen -</option>
          <option v-for="title in filters.states" :value="title">{{renderProgressTitle(title)}}</option>
        </select>
      </div>
      <!--reset filter button -->
      <button class="btn btn-outline-primary btn-sm me-3" @click="licenseFilter = 'all';searchDate = new Date().toISOString().slice(0, 10); stateFilter = 'all'; searchAttest = ''"
              style="z-index: 25;position: relative;top: -2px;" position="left" tooltip="Filter zurücksetzen"><i
        class="fa-duotone fa-filter-slash"></i></button>

      <button class="float-end btn btn-sm me-3" :class="(showDeleted) ? 'btn-primary' : 'btn-outline-primary'"
              @click="toggleDeleted()" style="z-index: 25;position: relative;" position="left"
              tooltip="Alle Einträge anzeigen"><i class="fa-duotone fa-eye"></i></button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <template v-for="attest in filteredAttests" :key="attest.id">
            <div class="singleRequest" :class="(dateBiggerThanDate(attest.crm_created_date)) ? 'bg-warning' : ''"
                 v-if="(attest.verified !== 'denied' && attest.verified !== 'verified_complete' && attest.verified !== 'denied_complete' && !showDeleted) || (showDeleted && (attest.verified === 'verified_complete' || attest.verified === 'denied'|| attest.verified === 'denied_complete'))">
              <div class="pluginWrap insitutions">
                <div class="plugin-item">
                  <div class="lineitem l-date" style=" font-size: 12px; width: 130px;">
                    <template v-if="attest.verified ==='waiting'">
                      <input @blur="quickSave(attest)" type="date" v-model="attest.valid_from"/>
                    </template>
                    <template v-else>
                      {{ $filters.formatDate(attest.valid_from) }}
                    </template>
                  </div>
                  <div class="lineitem l-date" tooltip="Abgabedatum" style=" font-size: 12px; width: 130px;">

                      {{ $filters.formatDate(attest.crm_created_date) }}

                  </div>
                  <div class="lineitem l-progress l-state" style="font-size: 12px; vertical-align: top" v-html="renderProgress(attest._request.state)">
                  </div>
                  <div class="lineitem l-state">
                  <span class="stateIcon">
                    <template v-if="attest.verified ==='verified_waiting'">
                      <span tooltip="Akzeptiert #1" style="color: yellowgreen;"><i
                        class="fa-duotone fa-check-circle fa-xl"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='verified_complete'">
                      <span tooltip="Akzeptiert #2" style="color: green;"><i
                        class="fa-duotone fa-check-circle fa-xl"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='waiting'">
                      <span tooltip="Wartend" style="color: orange;"><i class="fa-duotone fa-clock  fa-xl"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='denied_waiting'">
                      <span tooltip="Ablehnung #1" style="color: indianred;"><i class="fa-duotone fa-xmark-circle  fa-xl"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='denied_complete'">
                      <span tooltip="Ablehnung #2" style="color: #d13b3b;"><i class="fa-duotone fa-xmark-circle  fa-xl"></i></span>
                    </template>
                    <template v-else>
                      <span tooltip="Abgelehnt / Gelöscht" style="color: darkred;"><i
                        class="fa-duotone fa-xmark-circle  fa-xl"></i></span></template>
                     </span>
                  </div>

                  <div class="lineitem l-title" style="font-size: 12px">
                    {{ attest._customerName }}<br>
                    {{ attest._attestTitle }}<br>
                    {{ attest._validState }}
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px">
{{attest._request._requestTitle}}<br>{{$filters.formatDate(attest._request._requestStart)}}<br>Punkte: {{attest._request.finalPoints}}
                  </div>

                  <template v-if="attest.verified === 'denied_waiting' || attest.verified === 'denied_complete'">

                    <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">
                       <template v-if="attest.denied_waiting_by === getUser._isEmployee"><button @click="revokeAttest(attest)" class="btn btn-outline-danger float-start btn-xs me-2" tooltip="Zurückziehen" position="right"><i
                      class="fa-duotone fa-xmark-circle"></i></button></template>
                      {{ attest._verifiedWaitingBy }}<br>
                      {{ $filters.formatDateTime(attest._verifiedWaitingDate) }}

                      <div class="vComment" style="    color: grey;
    font-style: italic;">
                      Kommentar: {{attest.verified_comment}}
                      </div>
                    </div>
                    <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">
                      {{ attest._verifiedBy }}<br>
                      {{ $filters.formatDateTime(attest._verifiedDate) }}
                    </div>

                  </template>

                  <template v-else>
                  <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">
                     <template v-if="attest.verified_waiting_by === getUser._isEmployee"><button @click="revokeAttest(attest)" class="btn btn-outline-danger float-start btn-xs me-2" tooltip="Zurückziehen" position="right"><i
                    class="fa-duotone fa-xmark-circle"></i></button></template>
                    {{ attest._verifiedWaitingBy }}<br>
                    {{ $filters.formatDateTime(attest._verifiedWaitingDate) }}
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">
                    {{ attest._verifiedBy }}<br>
                    {{ $filters.formatDateTime(attest._verifiedDate) }}
                  </div>
                  </template>
                  <div class="lineitem l-action l-preview">
                    <button @click="activeAttest = attest;getAttestFile(attest)"
                            v-if="attest.verified ==='verified_waiting' || attest.verified ==='denied_waiting' || attest.verified === 'waiting'"
                            class="btn btn-outline-primary btn-xs float-end me-2"
                            tooltip="Vorschau" position="left"><i class="fa-duotone fa-eye"></i></button>
                    <button @click="showNotices(attest)" class="btn btn-xs float-end me-2" style="width: 24px;" :class="(attest._notices && attest._notices['attest_'+attest.attest_type] && attest._notices['attest_'+attest.attest_type].length > 0) ? 'btn-primary' : 'btn-outline-primary'" tooltip="Notizen" position="left"><i class="fa-duotone fa-file-alt"></i></button>

                  </div>
                  <div class="lineitem l-action ml-auto">
                    <button @click="denyAttest(attest)" class="btn btn-outline-danger btn-xs float-end me-2"
                            tooltip="Ablehnen" :disabled="(attest.verified === 'denied')" position="left"><i
                      class="fa-duotone fa-xmark-circle"></i></button>
                    <button @click="checkAttest(attest)" class="btn btn-outline-success btn-xs float-end me-2"
                            v-if="attest.verified ==='verified_waiting' || attest.verified === 'waiting'"
                            :disabled="(attest.state ==='0')" tooltip="Akzeptieren" position="left"><i
                      class="fa-duotone fa-check-circle"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!--Show Active Attest in Modal Preview with vuepdfembed-->
  <Modal v-if="activeAttest" @close="activeAttest = null;previewPdf=''">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5>{{ activeAttest._attestTitle }}</h5>
            <button class="btn-close" @click="activeAttest = null; previewPdf='';zoomLevel = 1;"></button>
          </div>
          <div class="modal-body">
            <template v-if="previewPdf && isPdf(previewPdf)">
              <vue-pdf-embed :source="previewPdf"/>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12 text-center">
                  <div class="btn-group">
                    <button @click="zoomIn" class="btn btn-outline-primary btn-sm mb-2"><i
                      class="fa-duotone fa-magnifying-glass-plus"></i></button>
                    <button @click="zoomOut" class="btn btn-outline-primary btn-sm mb-2"><i
                      class="fa-duotone fa-magnifying-glass-minus"></i></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <p class="mb-1"><small><i class="fa-duotone fa-computer-mouse-scrollwheel color-purple"></i> Das
                    Mausrad kann zum Zoomen verwendet werden</small>
                  </p></div>
              </div>
              <div class="row" @wheel.prevent="handleWheel($event)">
                <div class="col-12" style="overflow: hidden;">
                  <img :src="previewPdf"
                       :style="{ transform: `scale(${zoomLevel})`, transformOrigin: transformOrigin }"
                       style="display: block; width: 100%; height: auto;">
                </div>
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="showComment" @close="showComment = false">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Kommentar {{ activeAttest._attestTitle }} - {{ activeAttest._customerName }}</h5>
            <button class="btn-close" @click="showComment = false; activeAttest = null"></button>
          </div>
          <div class="modal-body">
            <textarea v-model="comment" class="form-control" rows="10"></textarea>
          </div>
          <div class="modal-footer">
            <button class="btn btn-outline-primary" @click="sendComment"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="noticeActive">
    <div class="container">
      <div class="card" style="padding:24px; max-width: 80%">
        <div class="noticesContainer">

          <template v-for="n in notices">
            <div class="noticeItem" v-if="n.notice_type === noticeActive">
              <div class="noticeHeader mb-2">
                <button type="button" class="btn btn-outline-danger btn-sm float-end" @click="deleteNotice(n)"><i class="fa-duotone fa-trash"></i></button>
              </div>
              <div class="noticeMessage" v-html="n.notice"></div>
              <hr>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="columns xlarge-12 large-12 medium-12 small-12">
            <textarea style="width: 100%; min-height:200px; margin-bottom: 24px; padding: 24px;" v-model="noticeAdd" placeholder="Notiz erfassen"></textarea>
          </div>

          <div class="columns xlarge-6 large-6 medium-12 small-12">
            <button @click="noticeAdd = '';noticeActive = false" class="btn btn-outline-danger"><i class="fa-duotone fa-x"></i> Abbrechen</button>
          </div>
          <div class="columns xlarge-6 large-6 medium-12 small-12">
            <button @click="saveNotice()" class="btn btn-outline-primary  float-end"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<style>
.singleRequest.bg-warning {
  background-color: #f8d7da !important;
}
</style>
<script>
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      activeAttest: null,
      noticeActive: false,
      attests: [],
      filters: [],
      notices: [],
      noticeAdd: "",
      showDeleted: false,
      searchAttest: "",
      searchDate: "2024-10-10",
      showAddAttest: false,
      addAttestTitle: "",
      comment: "",
      previewPdf: "",
      showComment: false,
      zoomLevel: 1,
      transformOrigin: 'center center',
      renderState: {
        "active": {icon: "fa-duotone fa-edit", color: "#0088CC", text: "Vom Bewerber auszufüllen"},
        "progress": {icon: "fa-duotone fa-clipboard-list-check", color: "#ecaa12", text: "Vom DFB zu bearbeiten"},
        "checking": {icon: "fa-duotone fa-gear", color: "#ecaa12", text: "DFB in Bearbeitung"},
        "discus": {icon: "fa-duotone fa-gear", color: "#ecaa12", text: "DFB in Klärung"},
        "accepted": {icon: "fa-duotone fa-check", color: "#0088CC", text: "DFB akzeptiert"},
        "deleted": {icon: "fa-duotone fa-times", color: "#0088CC", text: "Vom Bewerber zurückgezogen"},
        "prio_1": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 1"},
        "prio_2": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 2"},
        "prio_3": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 3"},
        "prio_4": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 4"},
        "closed": {icon: "fa-duotone fa-times", color: "#0088CC", text: "Abgelehnt"},
        "declined": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined_01": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined_02": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Voraussetzungen nicht erfüllt"},
        "declined_03": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Korrigierte Punkte"},
        "declined_04": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Ungeprüft zurückgewiesen"},
        "declined_05": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Zu wenig Punkte"},
        "declined_user": {icon: "fa-duotone fa-circle-xmark", color: "gray", text: "Teilnehmerabsage"},
        "default": {icon: "fa-duotone fa-circle-xmark", color: "gray", text: "Fallback"},
      },
      licenseFilter: "all",
      stateFilter: "all",
      tmpCustomerId: null,
    };
  },
  components: {
    VuePdfEmbed
  },
  mounted() {
    this.getAttests();
  },
  computed: {
    filteredAttests() {
      const searchTerm = this.searchAttest.toLowerCase();

      return this.attests.filter((attest) => {
        // Convert search fields to lowercase for case-insensitive search
        const attestTitle = attest._attestTitle.toLowerCase();
        const customerName = attest._customerName.toLowerCase();
        if(attest._request._requestTitle){
        const requestTitle = attest._request._requestTitle.toLowerCase();
        }
        else{
          const requestTitle = '';
        }
        // Search term filter
        const matchesSearch = attestTitle.includes(searchTerm) ||
          customerName.includes(searchTerm) ||
          requestTitle.includes(searchTerm);

        // License filter (check against licenseFilter if it's not 'all')
        const matchesLicense = this.licenseFilter === 'all' ||
          attest._request._requestTitle === this.licenseFilter;

        // State filter (check against stateFilter if it's not 'all')
        const matchesState = this.stateFilter === 'all' ||
          attest._request.state === this.stateFilter;

        // Only include attests that match the search term, license, and state filters
        return matchesSearch && matchesLicense && matchesState;
      });
    },
    ...mapGetters(["getUser"]),
  },
  methods: {
    dateBiggerThanDate(date) {
      return new Date(date) > new Date(this.searchDate);
    },
    renderProgress(progress) {
      let returnProgress = '';
      returnProgress = this.renderState[progress];
      if (returnProgress === undefined) returnProgress = this.renderState['default'];
      let returnHtml ='';
      returnHtml = '<span tooltip="' + returnProgress.text + '" position="right" data-oro="' + progress + '"><i class="' + returnProgress.icon + '" style="color:' + returnProgress.color + '"></i></span>';
      return returnHtml;
    },
    renderProgressTitle(progress) {
      let returnProgress = '';
      returnProgress = this.renderState[progress];
      if (returnProgress === undefined) returnProgress = this.renderState['default'];
      return returnProgress.text;
    },
    toggleDeleted() {
      this.showDeleted = !this.showDeleted;
    },
    getAttests() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/attests", {headers}).then((r) => {
        this.attests = r.data.result.data;
        this.filters = r.data.result.filters;
      });
    },
    quickSave(attest) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // change date from attest.valid_from to correct format
      attest.valid_from = new Date(attest.valid_from).toISOString().slice(0, 10);
      axios.put(this.$store.state.apiUrl + "trainer/attest/" + attest.id + "/changeDate", attest, {headers}).then(() => {
        this.getAttests();
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.getAttests();
      });
    },
    showNotices(attest) {
      this.notices = attest._notices['attest_'+attest.attest_type];
      this.noticeActive = 'attest_'+attest.attest_type;
      this.tmpCustomerId = attest.crm_customer_id;
    },
    denyAttest(attest) {
      this.activeAttest = attest;
      this.comment = attest.verified_comment;
      this.showComment = true;
    },
    revokeAttest(attest) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let tempInst = attest;
      tempInst.verified = "denied";
      axios.put(this.$store.state.apiUrl + "trainer/attest/" + attest.id + "/revoke", tempInst, {headers}).then(() => {
        this.getAttests();
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.getAttests();
      });
    },
    zoomIn() {
      this.zoomLevel += 0.1;
    },
    zoomOut() {
      if (this.zoomLevel > 0.8) this.zoomLevel -= 0.1;
    },
    handleWheel(event) {
      const target = event.target; // This should be your image or embed element
      const rect = target.getBoundingClientRect();
      // Calculate cursor position inside the element as a percentage
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
      // Update transformOrigin based on cursor position
      this.transformOrigin = `${xPercent}% ${yPercent}%`;
      // Zoom in or out
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    sendComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let tempInst = this.activeAttest;
      tempInst.verified_comment = this.comment;
      axios.put(this.$store.state.apiUrl + "trainer/attest/" + this.activeAttest.id + "/deny", tempInst, {headers}).then(() => {
        this.getAttests();
        this.showComment = false;
        this.activeAttest = null;
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.getAttests();
        this.showComment = false;
        this.activeAttest = null;
      });
    },
    checkAttest(attest) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let tempInst = attest;
      tempInst.verified = "verified";
      axios.put(this.$store.state.apiUrl + "trainer/attest/" + attest.id + "/accept", tempInst, {headers}).then(() => {
        this.getAttests();
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.getAttests();
      });
    },
    getAttestFile(attest) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/attests/" + attest.id + "/file", {headers}).then((r) => {
        this.previewPdf = r.data.result.data.attest_pdf;
      });
    },
    isPdf(base64Data) {
      return base64Data.startsWith('data:application/pdf');
    },
    isNotice(type) {
      return this.notices.filter(n => n.notice_type === type).length > 0;
    },
    deleteNotice(notice){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "plugins/dfb/requestNotice/"+notice.id, {headers}).then(() => {
        this.noticeActive = false;
        this.getAttests();
      });

    },
    saveNotice(){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/requestNotice/0", {type: this.noticeActive, notice: this.noticeAdd, crm_customer_id: this.tmpCustomerId}, {headers}).then(() => {
        this.noticeActive = false;
        this.tmpCustomerId = null;
        this.getAttests();

        this.noticeAdd = '';
      });
    },
    addNotice($type) {
      this.noticeActive = $type;
    },
  },
};
</script>
